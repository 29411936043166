





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Status',
})
export default class StatusComponent extends Vue {
  @Prop(Boolean) private status!: boolean
}
